import {
	login,
	getUserInfo,
	logout,
	info,
	userSwitchLoginApi,
	permissionThirdListForLoginApi
} from '@/api/admin/base'
import {
	menuData
} from '@/assets/js/menu'
import {
	router_all
} from '@/assets/js/component'

export default {
	state: {
		addRoutes: [],
		actions: [],
		menu: [],
		menu_all: [],
		username: '',
		headimg: '',
		site_title: '',
		show_notice: true,
	},
	mutations: {
		setRoute(state, route) {
			state.addRoutes = route
		},
		setMenu(state, menu) {
			state.menu = menu
		},
		setActions(state, actions) {
			state.actions = actions
		},
		setUserInfo(state, val) {
			state.username = val.user
			state.headimg = val.headimg
			state.role_id = val.role_id
			state.site_title = val.site_title
			state.show_notice = val.show_notice
		},
		setAllMenu(state, menu) {
			state.menu_all = menu;

		}
	},
	actions: {
		userSwitchLogin({
			commit
		}, userInfo) {
			return new Promise((resolve, reject) => {
				userSwitchLoginApi(userInfo).then(res => {
					localStorage.setItem("adminToken", res.token);
					localStorage.setItem("loginName", res.loginName);
					localStorage.setItem("realName", res.nickName);
					localStorage.setItem("userId", res.userId);
					localStorage.setItem("roleType", res.roleType);
					localStorage.setItem("roleId", res.roleId);
					localStorage.setItem("adminIdentity", res.adminIdentity);
					localStorage.setItem("adminId", res.adminId);
					localStorage.setItem("cityCode", res.cityCode);
					// localStorage.setItem("setMenu_newMenu", '');
					// localStorage.setItem("adminIdentity_index", 0);
					resolve()
				}).catch(error => {
					reject(error)
				})
			})
		},
		login({
			commit
		}, userInfo) {
			return new Promise((resolve, reject) => {
				login(userInfo).then(res => {
					localStorage.setItem("adminToken", res.token);
					localStorage.setItem("loginName", res.loginName);
					localStorage.setItem("realName", res.nickName);
					localStorage.setItem("userId", res.userId);
					localStorage.setItem("roleType", res.roleType);
					localStorage.setItem("roleId", res.roleId);
					localStorage.setItem("adminIdentity", res.adminIdentity);
					localStorage.setItem("adminId", res.adminId);
					localStorage.setItem("cityCode", res.cityCode);

					resolve()
				}).catch(error => {
					reject(error)
				})
			})
		},
		// info() {
		// 	return new Promise((resolve, reject) => {
		// 		info({
		// 			roleId: localStorage.getItem('roleId')
		// 		}).then(res => {
		// 			console.log('ff', res)
		// 			resolve()
		// 		}).catch(error => {
		// 			reject(error)
		// 		})
		// 	})
		// },
		getUserInfo({
			commit
		}) {
			let routes = router_all
			let baseRoutes = [{
				path: '/',
				component: (resolve) => require(['@/layout/admin/default/Main'], resolve),
				children: []
			}, ]
			routes.forEach(item => {
				if (item.component_path) {
					item.component = (resolve) => require([`@/views/${item.component_path}`], resolve)
					baseRoutes[0].children.push(item)
				}
			})
			commit('setRoute', baseRoutes.concat({
				path: '*',
				redirect: '/404'
			}))
			// localStorage.getItem('adminIdentity') == 1
			// localStorage.getItem('adminIdentity_index') == 1
			if (localStorage.getItem('adminIdentity') == 1) {
				// 左侧菜单
				// let menu = JSON.parse(localStorage.getItem("setMenu_newMenu"))
				// commit('setMenu', menu);
				commit('setMenu', menuData);
			} else {
				// 权限
				//commit('setMenu', menuData);
				return new Promise((resolve, reject) => {
					//   info({roleId:localStorage.getItem('roleId')}).then(res => {
					// let newMenu = [];
					// let objString  = JSON.stringify(res.data.permissionTreeVoList);
					// let obj2 = JSON.parse(objString);
					// let obj3 = JSON.parse(objString);
					// let obj4 = JSON.parse(objString);
					// let obj5 = JSON.parse(objString);
					// commit('setAllMenu',obj5);
					// for(let i in res.data.permissionTreeVoList){
					// 	res.data.permissionTreeVoList[i].path = res.data.permissionTreeVoList[i].permissionRoute;
					// 	res.data.permissionTreeVoList[i].title = res.data.permissionTreeVoList[i].permissionDesc;
					// 	res.data.permissionTreeVoList[i].icon = res.data.permissionTreeVoList[i].permissionIcon
					// 	newMenu.push(res.data.permissionTreeVoList[i]);
					// 	if(res.data.permissionTreeVoList[i].hasOwnProperty('permissionChildren')){
					// 		newMenu[i].children = [];
					// 		for(let j in obj2[i].permissionChildren){
					// 			if(obj2[i].permissionChildren[j].permissionRoute!='model'){
					// 				obj2[i].permissionChildren[j].path = obj2[i].permissionChildren[j].permissionRoute;
					// 				obj2[i].permissionChildren[j].title = obj2[i].permissionChildren[j].permissionDesc;
					// 				obj2[i].permissionChildren[j].icon = obj2[i].permissionChildren[j].permissionIcon
					// 				newMenu[i].children.push(obj2[i].permissionChildren[j]);
					// 			}
					// 		}
					// 	}
					// }
					//       commit('setMenu', newMenu); 
					//       resolve()
					//   }).catch(error => {
					//       reject(error)
					//   })
					
					permissionThirdListForLoginApi({}).then(res => {
						localStorage.setItem("permissionThirdData", JSON.stringify(res
							.data));
					})

					// 新版本权限
					info().then(res => {
						let newMenu = [];
						let objString = JSON.stringify(res.data);
						let obj2 = JSON.parse(objString);
						let obj3 = JSON.parse(objString);
						let obj4 = JSON.parse(objString);
						let obj5 = JSON.parse(objString);
						// commit('setAllMenu', obj5);
						for (let i in res.data) {
							res.data[i].path = res.data[i].permissionRoute;
							res.data[i].title = res.data[i].permissionDesc;
							res.data[i].icon = res.data[i].permissionIcon
							newMenu.push(res.data[i]);
							if (res.data[i].hasOwnProperty('permissionChildren')) {
								newMenu[i].children = [];
								for (let j in obj2[i].permissionChildren) {
									if (obj2[i].permissionChildren[j].permissionRoute != 'model') {
										obj2[i].permissionChildren[j].path = obj2[i].permissionChildren[
											j].permissionRoute;
										obj2[i].permissionChildren[j].title = obj2[i]
											.permissionChildren[j].permissionDesc;
										obj2[i].permissionChildren[j].icon = obj2[i].permissionChildren[
											j].permissionIcon
										newMenu[i].children.push(obj2[i].permissionChildren[j]);
									}
								}
							}
						}
						localStorage.setItem("setMenu_newMenu", JSON.stringify(newMenu));
						localStorage.setItem("adminIdentity_index", 1);
						commit('setMenu', newMenu);
						resolve()
						// commit('setMenu', newMenu);
						// localStorage.setItem("isLogin", true);
					}).catch(error => {
						reject(error)
					})
				})
			}
		},
		logout({
			commit
		}) {
			return new Promise((resolve, reject) => {
				const adminToken = localStorage.getItem("token")
				logout({
					adminToken: adminToken
				}).then(res => {
					if (res.status == 200) {
						localStorage.setItem("token", "")
						commit('setRoute', [])
						commit('setMenu', [])
						commit('setActions', [])
						commit('setUserInfo', {})
						localStorage.setItem("setMenu_newMenu", '');
						localStorage.setItem("adminIdentity_index", 0);
					}
					resolve()
				}).catch(error => {
					reject(error)
				})
			})
		}

	}
}