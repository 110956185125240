var router_all = [
	
    {
        component_path: "admin/base/home.vue",
        meta: {title: "首页"},
        name: "Home",
        path: "/admin/Home/index.html",
    },
	{
	    component_path: "admin/ledger/signList.vue",
	    meta: {title: "签约即收佣"},
	    name: "signList",
	    path: "/admin/Ledger/signList.html",
	},
	{
	    component_path: "admin/ledger/lease.vue",
	    meta: {title: "租赁收佣"},
	    name: "lease",
	    path: "/admin/Ledger/lease.html",
	},
	{
	    component_path: "admin/ledger/transaction.vue",
	    meta: {title: "租赁交易"},
	    name: "ledgerTransaction",
	    path: "/admin/Ledger/transaction.html",
	},
	{
	    component_path: "admin/ledger/index.vue",
	    meta: {title: "二手房账单"},
	    name: "Ledger",
	    path: "/admin/Ledger/index.html",
	},
	{
	    component_path: "admin/pay/index.vue",
	    meta: {title: "打款列表"},
	    name: "Payment",
	    path: "/admin/Payment/index.html",
	},{
	    component_path: "admin/pay/record.vue",
	    meta: {title: "打款记录"},
	    name: "Paymentrecord",
	    path: "/admin/Payment/record.html",
	},{
	    component_path: "admin/pay/detail.vue",
	    meta: {title: "实收详情"},
	    name: "PaymentDetail",
	    path: "/admin/Payment/detail.html",
	},{
	    component_path: "admin/pay/billConfirm.vue",
	    meta: {title: "账单确认记录"},
	    name: "billConfirm",
	    path: "/admin/Payment/billConfirm.html",
	},
	{
	    component_path: "admin/cost/detail.vue",
	    meta: {title: "费用详情"},
	    name: "CostDetail",
	    path: "/admin/CostDetail/index.html",
	},
	{
	    component_path: "admin/abnormal/cash.vue",
	    meta: {title: "收款审核记录"},
	    name: "Acash",
	    path: "/admin/Acash/index.html",
	},
	
	{
	    component_path: "admin/aftermarket/policy.vue",
	    meta: {title: "售后分单"},
	    name: "Policy",
	    path: "/admin/Policy/index.html",
	},
	{
	    component_path: "admin/netsign/index.vue",
	    meta: {title: "网签申请"},
	    name: "Policy",
	    path: "/admin/netsign/index.html",
	},
	{
	    component_path: "admin/netsign/detail.vue",
	    meta: {title: "网签申请详情"},
	    name: "netsignDetail",
	    path: "/admin/netsign/detail.html",
	},
	{
	    component_path: "admin/data/dales.vue",
	    meta: {title: "明细"},
	    name: "Sdales",
	    path: "/admin/Sdales/index.html",
	},
	
	{
	    component_path: "admin/ledger/entry.vue",
	    meta: {title: "录入记录"},
	    name: "Entry",
	    path: "/admin/Entry/index.html",
	},
	{
	    component_path: "admin/ledger/offset.vue",
	    meta: {title: "抵充记录"},
	    name: "Offset",
	    path: "/admin/Offset/index.html",
	},
	
	{
	    component_path: "admin/ledger/application.vue",
	    meta: {title: "店东申请"},
	    name: "ownerA",
	    path: "/admin/ownerA/index.html",
	},
	{
	    component_path: "admin/data/customer.vue",
	    meta: {title: "客诉统计"},
	    name: "Customer",
	    path: "/admin/Customer/index.html",
	},
	
	{
	    component_path: "admin/data/coverage.vue",
	    meta: {title: "门店覆盖率"},
	    name: "Coverage",
	    path: "/admin/Coverage/index.html",
	},
	{
	    component_path: "admin/map/index.vue",
	    meta: {title: "门店概览"},
	    name: "Map",
	    path: "/admin/Map/index.html",
	},
	
	
	
	{
	    component_path: "admin/ledger/detail.vue",
	    meta: {title: "分账审核详情"},
	    name: "LedgerDetail",
	    path: "/admin/LedgerDetail/index.html",
	},
	{
	    component_path: "admin/ledger/detail1.vue",
	    meta: {title: "详情"},
	    name: "LedgerDetailuser",
	    path: "/admin/LedgerDetailuser/index.html",
	},
	
	{
	    component_path: "admin/aftermarket/bank.vue",
	    meta: {title: "贷款明细统计"},
	    name: "AfterBank",
	    path: "/admin/AfterBank/index.html",
	},
	
	{
	    component_path: "admin/aftermarket/bankCount.vue",
	    meta: {title: "贷款银行汇总"},
	    name: "bankCount",
	    path: "/admin/bankCount/index.html",
	},
	{
	    component_path: "admin/system/get.vue",
	    meta: {title: "全局设置"},
	    name: "getSetting",
	    path: "/admin/getSetting/index.html",
	},
	
	
	
	{
	    component_path: "admin/loan/index.vue",
	    meta: {title: "贷款预审"},
	    name: "Loan",
	    path: "/admin/Loan/index.html",
	},
	{
	    component_path: "admin/loan/add.vue",
	    meta: {title: "贷款预审"},
	    name: "LoanAdd",
	    path: "/admin/LoanAdd/index.html",
	},

	{
	    component_path: "admin/ledger/statement.vue",
	    meta: {title: "分账账单"},
	    name: "Statement",
	    path: "/admin/Statement/index.html",
	},
	
	
	{
	    component_path: "admin/ledger/account.vue",
	    meta: {title: "分账账单统计"},
	    name: "LedgerAccount",
	    path: "/admin/LedgerAccount/index.html",
	},
	{
	    component_path: "admin/ledger/shop.vue",
	    meta: {title: "门店应收录入"},
	    name: "LedgerShop",
	    path: "/admin/LedgerShop/index.html",
	},
	{
	    component_path: "admin/ledger/withdraw.vue",
	    meta: {title: "提现记录"},
	    name: "LedgerShop",
	    path: "/admin/withdraw/index.html",
	},
	{
	    component_path: "admin/ledger/withdrawDetail.vue",
	    meta: {title: "提现详情"},
	    name: "withdrawDetail",
	    path: "/admin/withdraw/detail.html",
	},
	{
	    component_path: "admin/Secondhouse/index.vue",
	    meta: {title: "二手房交易"},
	    name: "Secondhouse",
	    path: "/admin/Secondhouse/index.html",
	},
	{
	    component_path: "admin/abnormal/index.vue",
	    meta: {title: "异常分账"},
	    name: "Abnormal",
	    path: "/admin/Abnormal/index.html",
	},
	{
	    component_path: "admin/manager/walker.vue",
	    meta: {title: "任务列表"},
	    name: "walkerList",
	    path: "/admin/walkerList/index.html",
	},
	
	
	{
	    component_path: "admin/expense/index.vue",
	    meta: {title: "消息审核"},
	    name: "Expense",
	    path: "/admin/Expense/index.html",
	},
	{
	    component_path: "admin/expense/shop.vue",
	    meta: {title: "新店加盟"},
	    name: "ExpenseShop",
	    path: "/admin/ExpenseShop/index.html",
	},
	{
	    component_path: "admin/ledger/finance.vue",
	    meta: {title: "营收账单"},
	    name: "Finance",
	    path: "/admin/Finance/index.html",
	},
	{
	    component_path: "admin/ledger/document.vue",
	    meta: {title: "收款记录"},
	    name: "Document",
	    path: "/admin/Document/index.html",
	},
	
	
	{
	    component_path: "admin/aftermarket/user.vue",
	    meta: {title: "人员列表"},
	    name: "AfterUser",
	    path: "/admin/AfterUser/index.html",
	},
	{
	    component_path: "admin/walker/index.vue",
	    meta: {title: "店东账号"},
	    name: "Walker",
	    path: "/admin/Walker/index.html",
	},
	

	{
		component_path: "admin/store/index.vue",
		meta: {title: "门店列表"},
		name: "Store",
		path: "/admin/Store/index.html",
	},
	{
		component_path: "admin/store/account.vue",
		meta: {title: "门店账户"},
		name: "Store",
		path: "/admin/Store/account.html",
	},
	{
		component_path: "admin/nav/log.vue",
		meta: {title: "跟进记录"},
		name: "NavLog",
		path: "/admin/NavLog/index.html",
	},
	{
		component_path: "admin/rewards/index.vue",
		meta: {title: "奖惩记录"},
		name: "Rewards",
		path: "/admin/Rewards/index.html",
	},
	
	{
		component_path: "admin/manager/index.vue",
		meta: {title: "专管员列表"},
		name: "Manager",
		path: "/admin/Manager/index.html", 
	},
	
	{
		component_path: "admin/manager/log.vue",
		meta: {title: "巡店日志"},
		name: "walkerLogList",
		path: "/admin/walkerLogList/index.html",
	},
	{
		component_path: "admin/cost/index.vue",
		meta: {title: "费用管理"},
		name: "Cost",
		path: "/admin/Cost/index.html",
	},
	{
		component_path: "admin/poster/index.vue",
		meta: {title: "Banner图"},
		name: "Poster",
		path: "/admin/Poster/index.html",
	},
	{
		component_path: "admin/feedback/index.vue",
		meta: {title: "消息反馈"},
		name: "Feedback",
		path: "/admin/Feedback/index.html",
	},
	{
		component_path: "admin/report/index.vue",
		meta: {title: "战报生成"},
		name: "Report",
		path: "/admin/Report/index.html",
	},
	{
	    component_path: "admin/department/index.vue",
	    meta: {title: "组织架构"},
	    name: "Department",
	    path: "/admin/Department/index.html",
	},
	{
	    component_path: "admin/department/departmentDetail.vue",
	    meta: {title: "部门详情"},
	    name: "departmentDetail",
	    path: "/admin/departmentDetail/index.html",
	},
	{
	    component_path: "admin/department/detail.vue",
	    meta: {title: "员工详情"},
	    name: "staffDetail",
	    path: "/admin/staffDetail/index.html",
	},
    {
        component_path: "admin/position/index.vue",
        meta: {title: "职位列表"},
        name: "Position",
        path: "/admin/Position/index.html",
    },
	{
	    component_path: "admin/aftermarket/index.vue",
	    meta: {title: "售后订单"},
	    name: "Aftermarket",
	    path: "/admin/Aftermarket/index.html",
	},
	{
	    component_path: "admin/aftermarket/evaluation.vue",
	    meta: {title: "评价管理"},
	    name: "Evaluation",
	    path: "/admin/Evaluation/index.html",
	},
	
	{
	    component_path: "admin/aftermarket/detail.vue",
	    meta: {title: "订单详情"},
	    name: "AfterDetail",
	    path: "/admin/AfterDetail/index.html",
	},
	

	{
		component_path: "admin/type/index.vue",
		meta: {title: "公告列表"},
		name: "Type",
		path: "/admin/Type/index.html", 
	},
	{
		component_path: "admin/punishment/index.vue",
		meta: {title: "奖惩公告"},
		name: "Punishment",
		path: "/admin/Punishment/index.html", 
	},
	{
		component_path: "admin/type/notice.vue",
		meta: {title: "首页公告设置"},
		name: "TypeNotice",
		path: "/admin/TypeNotice/index.html", 
	},
	
	
	{
		component_path: "admin/check/index.vue",
		meta: {title: "审核消息"},
		name: "StoreCheck",
		path: "/admin/StoreCheck/index.html",
	},
	
	
	{
		component_path: "admin/store/update.vue",
		meta: {title: "编辑门店"},
		name: "StoreUpdate",
		path: "/admin/StoreUpdate/index.html",
	},
	{
		component_path: "admin/sign/index.vue",
		meta: {title: "培训列表"},
		name: "Sign",
		path: "/admin/Sign/index.html",
	},
	{
		component_path: "admin/sign/set.vue",
		meta: {title: "培训设置"},
		name: "SignSet",
		path: "/admin/SignSet/index.html",
	},
	
	{
		component_path: "admin/contract/index.vue",
		meta: {title: "加盟合同"},
		name: "Contract",
		path: "/admin/Contract/index.html",
	},
	{
		component_path: "admin/contract/add.vue",
		meta: {title: "创建合同"},
		name: "ContractAdd",
		path: "/admin/ContractAdd/index.html",
	},
	{
		component_path: "admin/contract/update.vue",
		meta: {title: "合同详情"},
		name: "ContractUpdate",
		path: "/admin/ContractUpdate/index.html",
	},
	{
		component_path: "admin/goods/check.vue",
		meta: {title: "业务合同"},
		name: "GoodsH",
		path: "/admin/GoodsH/index.html",
	},
	
	{
		component_path: "admin/order/index.vue",
		meta: {title: "巡店管理"},
		name: "Order",
		path: "/admin/Order/index.html",
	},
	
	
	
	{
		component_path: "admin/order/check.vue",
		meta: {title: "任务列表"},
		name: "Check",
		path: "/admin/Check/index.html",
	},
	
	{
		component_path: "admin/nav/index.vue",
		meta: {title: "报备列表"},
		name: "Nav",
		path: "/admin/Nav/index.html",
	},
	
	
	{
		component_path: "admin/system/index.vue",
		meta: {title: "日志管理"},
		name: "System",
		path: "/admin/System/index.html",
	},
	
	{
		component_path: "admin/statistics/overview.vue",
		meta: {title: "佣金概况"},
		name: "Overview",
		path: "/admin/Overview/index.html", 
	},
	

	
	{
		component_path: "admin/nav/order.vue",
		meta: {title: "加盟政策"},
		name: "NavOrder",
		path: "/admin/NavOrder/index.html", 
	},
	
	{
		component_path: "admin/transaction/index.vue",
		meta: {title: "门店交易"},
		name: "Transaction",
		path: "/admin/Transaction/index.html", 
	},
	{
		component_path: "admin/transaction/detail.vue",
		meta: {title: "门店交易详情"},
		name: "TransactionDetail",
		path: "/admin/TransactionDetail/index.html", 
	},
	{
		component_path: "admin/complaint/index.vue",
		meta: {title: "客诉列表"},
		name: "Complaint",
		path: "/admin/Complaint/index.html", 
	},
	{
		component_path: "admin/complaint/complaintCount.vue",
		meta: {title: "客诉统计"},
		name: "complaintCount",
		path: "/admin/Complaint/complaintCount.html", 
	},
	{
		component_path: "admin/complaint/complaintFormSet.vue",
		meta: {title: "投诉形式设置"},
		name: "complaintFormSet",
		path: "/admin/Complaint/complaintFormSet.html", 
	},
	{
		component_path: "admin/complaint/complaintClsSet.vue",
		meta: {title: "投诉分类设置"},
		name: "complaintClsSet",
		path: "/admin/Complaint/complaintClsSet.html", 
	},
	{
		component_path: "admin/complaint/complaintRemindSet.vue",
		meta: {title: "客诉提醒设置"},
		name: "complaintRemindSet",
		path: "/admin/Complaint/complaintRemindSet.html", 
	},
	{
		component_path: "admin/complaint/detail.vue",
		meta: {title: "客诉详情"},
		name: "ComplaintDetail",
		path: "/admin/ComplaintDetail/index.html", 
	},
	
	{
	    component_path: "admin/manage/index.vue",
	    meta: {title: "管理员列表"},
	    name: "Manage",
	    path: "/admin/Manage/index.html",
	},
	
	{
	    component_path: "admin/role/index.vue",
	    meta: {title: "角色列表"},
	    name: "Role",
	    path: "/admin/Role/index.html",
	},
	{
	    component_path: "admin/role/add.vue",
	    meta: {title: "新增角色"},
	    name: "RoleAdd",
	    path: "/admin/RoleAdd/index.html",
	},
	{
	    component_path: "admin/role/applicationNum.vue",
	    meta: {title: "应用人数"},
	    name: "applicationPeopleNum",
	    path: "/admin/applicationPeopleNum/index.html",
	},
	
	
	{
	    component_path: "admin/menu/index.vue",
	    meta: {title: "菜单列表"},
	    name: "Menu",
	    path: "/admin/Menu/index.html",
	},

	{
	    component_path: "admin/role/add.vue",
	    meta: {title: "新增角色"},
	    name: "RoleAdd",
	    path: "/admin/RoleAdd/index.html",
	},
	{
		component_path: "admin/role/update.vue",
		meta: {title: "编辑角色"},
		name: "RoleUpdate",
		path: "/admin/RoleUpdate/index.html",
	},	
	{
	    component_path: "admin/role/applicationNum.vue",
	    meta: {title: "应用人数"},
	    name: "applicationPeopleNum",
	    path: "/admin/applicationPeopleNum/index.html",
	},
	{
		component_path: "admin/goods/index.vue",
		meta: {title: "商品列表"},
		name: "Goods",
		path: "/admin/Goods/index.html",
	},
	{
        component_path: "admin/goodscata/index.vue",
        meta: {title: "商品分类"},
        name: "Goodscata",
        path: "/admin/Goodscata/index.html",
    },

	



	{
	    component_path: "admin/sale/shop_detail.vue",
	    meta: {title: "订单详情"},
	    name: "shopDetail",
	    path: "/admin/shopDetail/index.html",
	},
	{
	    component_path: "admin/sale/active.vue",
	    meta: {title: "活动订单统计"},
	    name: "SaleActive",
	    path: "/admin/SaleActive/index.html",
	},
	{
	    component_path: "admin/sale/order.vue",
	    meta: {title: "车检订单统计"},
	    name: "SaleOrder",
	    path: "/admin/SaleOrder/index.html",
	},
	
	
	{
	    component_path: "admin/freight/add.vue",
	    meta: {title: "新增运费模板"},
	    name: "FreightAdd",
	    path: "/admin/FreightAdd/index.html",
	},
	{
	    component_path: "admin/freight/update.vue",
	    meta: {title: "编辑运费模板"},
	    name: "FreightUpdate",
	    path: "/admin/FreightUpdate/index.html",
	},
	
	
	
	{
	    component_path: "admin/sale/goods.vue",
	    meta: {title: "商品销量排行"},
	    name: "SaleGoods",
	    path: "/admin/SaleGoods/index.html",
	},
	
	{
	    component_path: "admin/data/bill.vue",
	    meta: {title: "数据报表"},
	    name: "Bill",
	    path: "/admin/Bill/index.html",
	},
	{
	    component_path: "admin/data/specialist.vue",
	    meta: {title: "运营专员统计"},
	    name: "Specialist",
	    path: "/admin/Specialist/index.html",
	},
	
	{
	    component_path: "admin/data/detail.vue",
	    meta: {title: "详情"},
	    name: "SpecialistDetail",
	    path: "/admin/SpecialistDetail/index.html",
	},
	
	{
	    component_path: "admin/billReport/performanceIndex.vue",
	    meta: {title: "业绩分成明细表"},
	    name: "performanceIndex",
	    path: "/admin/performanceIndex/index.html",
	},
	{
	    component_path: "admin/billReport/transactionIndex.vue",
	    meta: {title: "我的交易列表"},
	    name: "transactionIndex",
	    path: "/admin/transactionIndex/index.html",
	},
	{
	    component_path: "admin/data/operation.vue",
	    meta: {title: "门店经营数据"},
	    name: "Operation",
	    path: "/admin/Operation/index.html",
	},
	{
	    component_path: "admin/data/operationdetail.vue",
	    meta: {title: "业绩详情"},
	    name: "OperationDetail",
	    path: "/admin/OperationDetail/index.html",
	},
	
	{
	    component_path: "admin/data/sales.vue",
	    meta: {title: "售后专员统计"},
	    name: "Sales",
	    path: "/admin/Sales/index.html",
	},
	
	
	
	{
	    component_path: "admin/data/total.vue",
	    meta: {title: "门店运营数据"},
	    name: "Total",
	    path: "/admin/Total/index.html",
	},


	{
		component_path: "admin/supplier/index.vue",
		meta: {title: "商户列表"},
		name: "Supplier",
		path: "/admin/Supplier/index.html",
	},
	{
		component_path: "admin/supplier/add.vue",
		meta: {title: "新增商户"},
		name: "SupplierAdd",
		path: "/admin/SupplierAdd/index.html",
	},
	{
		component_path: "admin/supplier/update.vue",
		meta: {title: "编辑商户"},
		name: "SupplierEdit",
		path: "/admin/SupplierEdit/index.html",
	},
	
	{
		component_path: "admin/store/add.vue",
		meta: {title: "新增门店"},
		name: "StoreAdd",
		path: "/admin/StoreAdd/index.html",
	},
	{
		component_path: "admin/store/detail.vue",
		meta: {title: "门店详情"},
		name: "StoreDetail",
		path: "/admin/StoreDetail/index.html",
	},
	
	
	{
		component_path: "admin/market/detail.vue",
		meta: {title: "详情"},
		name: "SignDetail",
		path: "/admin/SignDetail/index.html",
	},

	

	

	
	{
		component_path: "admin/circle/index.vue",
		meta: {title: "活动列表"},
		name: "Circle",
		path: "/admin/Circle/index.html",
	},
	{
		component_path: "admin/circle/record.vue",
		meta: {title: "报名列表"},
		name: "Record",
		path: "/admin/Record/index.html",
	},

	
	{
		component_path: "admin/market/index.vue",
		meta: {title: "费用缴纳"},
		name: "Market",
		path: "/admin/Market/index.html",
	},
	
	{
		component_path: "admin/market/index1.vue",
		meta: {title: "门店经营成本"},
		name: "Market1",
		path: "/admin/Market1/index.html",
	},

	

	


	{
		component_path: "admin/goods/add.vue",
		meta: {title: "新增商品"},
		name: "GoodsAdd",
		path: "/admin/GoodsAdd/index.html",
	},
	{
		component_path: "admin/goods/update.vue",
		meta: {title: "编辑商品"},
		name: "GoodsUdpate",
		path: "/admin/GoodsUdpate/index.html",
	},
	
	
	{
		component_path: "admin/clerk/index.vue",
		meta: {title: "店员列表"},
		name: "Clerk",
		path: "/admin/Clerk/index.html",
	},	
	{
		component_path: "admin/adminuser/index.vue",
		meta: {title: "用户管理"},
		name: "Adminuser",
		path: "/admin/Adminuser/index.html",
	},


	


	
	{
		component_path: "admin/order/detail.vue",
		meta: {title: "订单详情"},
		name: "Detail",
		path: "/admin/Detail/index.html",
	},

	
	
	


	{
        component_path: "admin/banner/index.vue",
        meta: {title: "奖惩日志"},
        name: "Banner",
        path: "/admin/Banner/index.html", 
    },
	
	{
        component_path: "admin/friend/index.vue",
        meta: {title: "账号管理"},
        name: "Friend",
        path: "/admin/Friend/index.html", 
    },
	{
	    component_path: "admin/friend/index1.vue",
	    meta: {title: "门店人员"},
	    name: "Friend1",
	    path: "/admin/Friend1/index.html", 
	},
	{
		component_path: "admin/service/index.vue",
		meta: {title: "招聘列表"},
		name: "Service",
		path: "/admin/Service/index.html", 
	},
	{
		component_path: "admin/service/list.vue",
		meta: {title: "岗位设置"},
		name: "ServiceList",
		path: "/admin/ServiceList/index.html", 
	},
	{
		component_path: "admin/after/index.vue",
		meta: {title: "订单列表"},
		name: "After",
		path: "/admin/After/index.html", 
	},
	{
		component_path: "admin/after/user.vue",
		meta: {title: "人员列表"},
		name: "AfterUser",
		path: "/admin/AfterUser/index.html", 
	},
	{
		component_path: "admin/after/set.vue",
		meta: {title: "设置"},
		name: "AfterSet",
		path: "/admin/AfterSet/index.html", 
	},
	
	{
		component_path: "admin/region/index.vue",
		meta: {title: "区域管理"},
		name: "Region",
		path: "/admin/Region/index.html", 
	},
	
	
	

]

export {router_all}