import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import jsSHA from "jssha"
import VueAMap from "vue-amap";

import '@/assets/scss/reset.scss'
import '@/assets/scss/index.scss'
import ElementUI from 'element-ui'
import Moment from 'moment'
import VueCookies from 'vue-cookies'
import axios from 'axios'
import 'element-ui/lib/theme-chalk/index.css'
import 'element-ui/lib/theme-chalk/display.css';
import '@/assets/icon/iconfont.css'
import '@/assets/icon/iconfont.js'
import Print from 'vue-print-nb'
import VDistpicker from 'v-distpicker'
import echarts from 'echarts'
import CanvasPoster from 'vue-canvas-poster'
Vue.use(CanvasPoster)
import { parseTime,formatStr,checkPermission} from "@/utils/common.js"
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'

Vue.use(Antd);
Vue.use(VDistpicker);
Vue.use(Print);
//inserted：被绑定元素插入父节点时调用 (仅保证父节点存在，但不一定已被插入文档中)
//如果bind获取不到el.parentNode
//试试把 bind 改为 inserted
const has = Vue.directive("has", {
    inserted: function(el, binding) {
        // console.log(binding,"w")
        // 获取按钮权限// 获取按钮权限
		// 判断是否是超级管理员，不是超管判断是否有按钮权限
		// if(localStorage.getItem('adminIdentity')!=1){
			if (!Vue.prototype.$_has(binding.value)) {
			    el.parentNode.removeChild(el);
			}
		// }
    }
});
// 权限检查方法（且把该方法添加到vue原型中）
Vue.prototype.$_has = function(value) {
    let isExit = false;
    let buttonpermsStr = JSON.parse(localStorage.getItem("permissionThirdData"));
    
    if (buttonpermsStr === undefined || buttonpermsStr=== null) {
        return false;
    }
    for (let i = 0; i < buttonpermsStr.length; i++) {
        let buttonpermsStrId = buttonpermsStr[i]
　　　　　　if (buttonpermsStrId.permissionName == value) {//要拿数据中不变的并且可以使用的字段进行判断，不可以使用id
            isExit = true;
            break;
        }
    }
    return isExit;
};
// 百度地图
import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap, {
  // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
  ak: '8MkVfkZg7sRCXrhioL9iFWaz9dwE6QWz'
})
import './permission'
Vue.use(VueCookies)
Vue.use(ElementUI)

Vue.prototype.$axios = axios
Vue.prototype.$echarts = echarts

Vue.config.productionTip = false
Vue.prototype.parseTime = parseTime
Vue.prototype.formatStr = formatStr
Vue.prototype.moment = Moment
Vue.prototype.checkPermission = checkPermission
Vue.use(VueAMap);

VueAMap.initAMapApiLoader({
	key: 'd3acb79f46858755ec9d791462a86836',
	plugin: [
		'AMap.Geocoder',
		'AMap.Autocomplete', // 输入提示插件
		'AMap.PlaceSearch', // POI搜索插件
		'AMap.Scale', // 右下角缩略图插件 比例尺
		'AMap.OverView', // 地图鹰眼插件
		'AMap.ToolBar', // 地图工具条
		'AMap.MapType', // 类别切换控件，实现默认图层与卫星图、实施交通图层之间切换的控制
		'AMap.PolyEditor', // 编辑 折线多，边形
		'AMap.CircleEditor', // 圆形编辑器插件
		'AMap.Geolocation' // 定位控件，用来获取和展示用户主机所在的经纬度位置
	],
	// 默认高德 sdk 版本为 1.4.4
	v: '1.4.4'
});
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
export { has };